@use '../../styles/mixins' as *;

.infocard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    
    @include tablet{
        margin-bottom: 0;
    }
    &:last-child{
        margin: 0;
        flex: 1;
    }

    &__title{
        border-bottom: solid 5px black;
        border-right: solid 2px black;
        align-self: stretch;
        padding:  0.5rem 1rem 0.5rem 0;
        font-size: 2rem;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-color: black;
        -webkit-text-stroke-width: 0.75px;
  

        @include tablet{
            font-size: 3rem;
        }
    }

    &__list{
        margin-top: 0.5rem;
        max-width: 100%;
        list-style-type: none;
        text-align: right;

        &-item{
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            padding: 0.25rem 0.5rem;
            font-weight: 600;
            font-size: 14px;
            margin-top: 0.25rem;
        }
    }
   


    &__content{
        font-size: 14px;
        margin-top: 0.5rem;
        padding: 1rem 7rem 1rem 0rem;
        height: 100%;
        line-height: 1.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
    } 

    &__link{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: inherit;
        text-decoration: none;
        padding: 0.25rem;
        position: relative;

        &:hover{
            &:after{
                width: 100%;
                right: 0;
            }
        }

        &:after{
            background: none repeat scroll 0 0 transparent;
            content: "";
            display: block;
            width: 0;
            height: 1px; 
            bottom: 0;
            right: 0;
            position: absolute;
            background: #000;
            transition: width 0.5s ease 0s, left 0.3s ease 0s;
        }

        &-arrow{

            font-size: 1.25rem;
            transition: all 1s ease;
            margin-left: 0.25rem;
            &:hover{
                cursor: pointer;
                transform: rotate(360deg) scale(1.2);
                transition: 1s all ease;
            }
        }

        &:hover{
            cursor: pointer;
        }

        &:visited{
            color: inherit;
            text-decoration: none;
        }
    }
}