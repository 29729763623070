@use '../../styles/mixins' as *;

.services{
    margin: 1rem;
    display: flex;
    flex-direction: column;

    @include tablet{
        max-width: 720px;
        margin: 1rem auto;
    }

    &__title{
        padding: 0.25rem 1rem;
        border-left: 6px solid black;
        border-bottom: 2px solid black;
        @include tablet{
            font-size: 2rem;
        }
    }

    &__wrapper{
        display: flex;
        flex-direction: column;
        align-self: center;
        margin: 1rem 0;

        
    }
}