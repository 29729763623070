@use '../../styles/mixins' as *;

.about{
    margin: 1rem;

    @include tablet{
        max-width: 720px;
        margin: 1rem auto;
    }

    &__title{
        padding: 0.25rem 1rem;
        border-bottom: 2px solid black;
        border-left: 6px solid black;
        @include tablet{
            font-size: 2rem;
        }
    }
}