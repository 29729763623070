@use '../../styles/mixins' as *;

.footer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
    padding: 1rem 0;
    border-top: 1px solid black;

    @include tablet{
        max-width: 720px;
        margin: 0 auto;
    }
    &__wrapper{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @include tablet{
            flex-direction: row;
        }
    }

    &__section{
        flex: 1;
        display: flex;
        flex-direction: column;

        &> p{
            margin: 0.25rem 0;
        }
    }

 

    &__title{
        font-size: 1.5rem;
    }

    &__subtitle{
        padding: 0.25rem;
        margin-bottom: 0.25rem;
        border-bottom: 3px solid black;
        border-right: 1px solid black;
    }

    &__email{
        display: flex;
        @include tablet{
            flex-direction: column;
        }
        &> a{
            padding-left: 0.25rem;
            color: inherit;
            @include tablet{
                padding: 0;
            }
            &:visited{
                color: inherit; 
            }
        }
    }

    &__hang{
        text-align: center;
        padding: 2rem;
    }
}
