@use '../../styles/mixins' as *;


.home{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    position: relative;
    padding-bottom: 1rem;

    @include tablet{
        max-width: 720px;
        margin: 0 auto;
    }

    &__clock{
        height: 100%;
        width: 100%;
    }
    &__wrapper{
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        
        @include tablet{
            flex-direction: row;
            justify-content: center;
            &> div{
                margin-right: 1rem;
            }
        }
    }

    &__svg{
        height: 120px;
    }
}