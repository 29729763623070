@use '../../styles/mixins' as *;

.scard{
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-bottom: none;
    @include tablet{
        border-bottom: none;
    }

    &__title{
        padding: 1rem;
        border-bottom: 1px solid black;
        @include tablet{
            font-size: 1.25rem;
        }
    }

    &__desc{
        padding: 1rem 2rem 1rem 1rem;
    }

    &:nth-last-of-type(1){
        border-bottom: 1px solid black;

        @include tablet{
            border-right: 1px solid black;
        }
    }
}