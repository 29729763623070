@use '../../styles/mixins' as *;

.contact{
    margin: 1rem;

    @include tablet{
        max-width: 720px;
        margin: 1rem auto;
    }

    &__title{
        padding: 0.25rem 1rem;
        border-bottom: 2px solid black;
        border-left: 6px solid black;
        margin-bottom: 1rem;
        @include tablet{
            margin-top: 1rem;
            font-size: 2rem;
        }
    }

    &__message{
        width: 90%;
        
        @include tablet{
            width: 100%;
        }
    }

    &__button{
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        font-weight: 500;
        background: black;
        color: white;
        border: solid 1px black;

        @include tablet{
            font-size: 1.25rem;
        }

        &:active{
            outline: none;
        }
        &:focus{
            outline: none;
        }
    }
}

.form{
    margin-top: 1rem;
   
    display: flex;
    flex-direction: column;
    &__wrapper{
        max-width: 100%;
        display: flex;
        flex-direction: column;

        @include mid-mobile{
            flex-direction: row;
        }
    }
}

.thanks{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    height: 200px;
    border: 1px solid black;
    position: relative;

    &__title{
        padding: 1rem;
        border-bottom: 1px solid black;
    }

    &__msg{
        padding: 1rem;
    }

    &-sign{
        position: absolute;
        bottom: 15px;
        padding: 1rem;
        padding-bottom: 0;
        align-self: flex-end;
    }
}