@use '../../styles/mixins' as *;
.input{
    
    &__wrapper{
        display: flex;
        flex-direction: column;
        align-self: stretch;
        flex: auto;
        margin-bottom: 1rem;
        border: 1px solid black;
        @include mid-mobile{
            
            &:first-of-type{
                margin-right: 0.5rem;
            }
            
        }
        
    }

    &__label{
        font-size: 13px;
        line-height: 1rem;

        max-width: 100%;
        padding: 0.5rem;

        @include tablet{
            font-size: 15px;
        }
    }

    &__input{
        border: none;
        padding: 0.5rem;
        max-width: 100%;
        border-top: 1px solid black;
      
        
        transition: border 0.5s ease;

       
        &:focus{
            outline: none;
            border-bottom: 6px solid black;
            transition: border 0.5s ease;
        }
    }

    &__error{
        margin-top: 0.25rem;
        font-size: 0.75rem;
        color: rgb(243, 31, 31);

        &::before{
            background-image: url('../../assets/icons/error-24px.svg');
            background-size: 1rem 1rem;
            height: 1rem;
            width: 1rem;
            display: inline-block;
            vertical-align: -18%;
            margin-right: 0.1rem;
            content: '';
        }
    }
}