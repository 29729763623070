@use '../../styles/mixins' as *;


.header{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
    padding: 1rem 0.5rem;
    // border-bottom: 1px solid black;

    @include tablet{
        max-width: 720px;
        margin: 0 auto 1rem;
    }

    &__logo{
        font-size: 1rem;
        text-decoration: none;
        &:visited{
            color: black;
            text-decoration: none;
        }
    }

    &__svg{
        height: 300px;
        max-width: 100%;

        @include tablet{
            height: 500px;
        }
    }

    &__hbmenu{
        height: 1.5rem;
        @include tablet{
            display: none;
        }
    }

    &__nav{

        &-item{
            // align-self: stretch;
            border-bottom: 1px solid black;
            font-weight: 500;
            text-decoration: none;
            width: 100%;
            padding-bottom: 1rem;
            text-align: right;

            &:visited{
                color: black;
            }
        }
    }

    &__dropdown{
        gap: 1rem;
        align-items: flex-end;
        margin: 1rem;
        display: flex;
        flex-direction: column;
        z-index: 100;
    }

    &__dropclose{
        position: fixed;
        height: 100vh;
        width: 100vw;
    }

    &__links{
        display: none;
        list-style-type: none;
        @include tablet{
            display: flex;
            align-self: center;
        }

        &-item{
            text-decoration: none;
            color: inherit;
            margin-right: 1rem;
            font-size: 0.5rem;
            text-transform: uppercase;

            @include desktop{
                font-size: 1rem;
            }

            &:visited{
                color: inherit;
            }
        }
    }
}