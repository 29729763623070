@mixin mid-mobile {
    @media (min-width: 540px){
        @content;
    }
}

@mixin tablet {
    @media (min-width: 768px){
        @content;
    }
}

//desktop rules
@mixin desktop {
    @media (min-width: 1280px){
        @content;
    }
}