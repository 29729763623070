@use '../../styles/mixins' as *;

.textarea{
    display: flex;
    flex-direction: column;
    border: 1px solid black;

    &__label{
        font-size: 13px;
        line-height: 1rem;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        @include tablet{
            font-size: 15px;
    
        }
    }
    &__input{
        max-width: 100%;
        align-self: stretch;
        resize: none;
        border: none;
     
      
        padding: 0.5rem 0.5rem;

        &:focus{
            outline: none;
            border-bottom: 6px solid black;
            transition: border 0.5s ease;
        }
    }

    &__error{
        margin-top: 0.25rem;
        font-size: 0.75rem;
        color: rgb(243, 31, 31);

        &::before{
            background-image: url('../../assets/icons/error-24px.svg');
            background-size: 1rem 1rem;
            height: 1rem;
            width: 1rem;
            display: inline-block;
            vertical-align: -18%;
            margin-right: 0.1rem;
            content: '';
        }
    }
}